<template>
  <div id="notFound">
    <section :style="{ backgroundImage: 'url(' + require('../../public/assets/i/pageTitle.jpg') + ')' }" class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0">
      <div class="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h1 class="display-1 mb-3 text-white">404 {{ langcontent.notfound }}</h1>
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ol class="breadcrumb text-white">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ langcontent.home }}</router-link>
                </li>
                <li aria-current="page" class="breadcrumb-item active">{{ langcontent.notfound }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light angled upper-end">
      <div class="container pb-11">
        <div class="row mb-14 mb-md-16">
          <div class="col-xl-10 mx-auto mt-n19">
            <div class="card">
              <div class="row gx-0">
                <div class="col-lg-12 align-self-stretch text-center">
                  <div class="map map-full rounded-top rounded-lg-start">
                    <img alt="404" src="assets/i/404.gif">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import iso from "../axios";

export default {
  data() {
    return {
      langcontent:[]
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Bimark | 404 '+this.langcontent.notfound;
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>

</style>